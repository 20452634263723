import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = [
	{
		path: '/',
		name: 'default',
		redirect: '/christmas',
	},
	// 1. 双旦活动
	// {
	// 	path: '/christmas',
	// 	name: 'christmas',
	// 	component: () => import('@views/christmas/index.vue'),
	// },
	// 2. 新年活动, 因为之前的双旦活动停了, 就之前的路由, 其他的一些入口项目就不用改了, 先这样
	{
		path: '/christmas',
		name: 'christmas',
		component: () => import('@views/new_year/index.vue'),
	},
];

export default new Router({
	mode: 'history',
	routes: router,
});
