import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import { HOSTUrls } from './services/config/host.config';
import '@assets/css/index.scss';
import '@components/vue2-toast/lib/toast.css';
import Toast from '@components/vue2-toast/src';

const thinkingdata = require('@utils/thinkingdata.umd.min.js');

Vue.use(Toast);

Vue.config.productionTip = false;

// 1. ----- 项目动态域名处理 start -------
// 本地修改环境变量直接替换为 dev / test / prod
// const isProdEnv = process.env.VUE_APP_CURRENTMODE || 'dev';
const isProdEnv = 'prod';
console.log('--- isProdEnv ---', isProdEnv, 'v1.1.0');

// host赋值
for (const hostName in HOSTUrls) {
	window[hostName] = HOSTUrls[hostName][isProdEnv];
	Vue.prototype[hostName] = HOSTUrls[hostName][isProdEnv];
}
// ----- 项目动态域名处理 end -------

const config = {
	appId: 'c62dee83605440bba94eb6f63996c866',
	serverUrl: 'https://tga.hortorgames.com',
	send_method: 'ajax',
};

// 将 SDK 实例赋给全局变量 ta，或者其他您指定的变量
window.ta = thinkingdata;
ta.init(config);
ta.track('init_new_year');

const vue = new Vue({
	router,
	render: (h) => h(App),
}).$mount('#app');

vue.env = isProdEnv;

export default vue;
