<template>
	<div id="app" ref="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',

	components: {},
};
</script>

<style scoped lang="scss">
#app {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
	background: #C51E13;
}
</style>
